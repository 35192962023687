import React from "react";
import {
  Switch,
  Pagination,
  Input,
  Modal,
  PageHeader,
  Button,
  Form,
  Spin,
  Row,
  Col,
  Select,
} from "antd";
import CustomModal from "../../components/common/CustomModal";
import { Link } from "react-router-dom";
import {
  getArmy,
  deActivateUser,
  deleteUser,
  updateProfile_user,
  removeRecord,
  getRecord,
} from "./actions/armyActions";
import { connect } from "react-redux";
import * as constant from "../../actions/constant";
import { export_table_to_csv } from "../../static/utils/utils";

const { confirm } = Modal;
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      totalRecord: 0,
      visible: false,
      search_string: "",
      userData: [],
      sortValue: "",
      sortOrder: "",
      loading: true,
      noItems: "",
      pageSize: 10,
      userId: "",
      editUser: [],
      nameSort: false,
      emailSort: false,
      createdSort: false,
    };
  }
  componentDidMount() {
    let obj = {
      string: this.state.search_string,
      page: this.state.currentPage,
      sort_by: this.state.sortValue,
      sort_order: this.state.sortOrder,
      limit: this.state.pageSize,
    };

    this.props.getArmy(obj);

    setTimeout(() => {
      this.setState({
        loading: false,
        noItems: "No Users Found",
      });
    }, 1000);
  }

  // showConfirm(userId) {
  //     let self = this;
  //     let obj = {
  //         userId: userId
  //     }
  //     let userObj = {
  //         searchString: this.state.search_string,
  //         page: this.state.currentPage,
  //         sortValue: this.state.sortValue,
  //         sortOrder: this.state.sortOrder,
  //         pageSize: this.state.pageSize

  //     }
  //     confirm({
  //         title: constant.DELETE_RECORD,
  //         content: '',
  //         onOk() { self.props.deleteUser(obj, userObj) },
  //         onCancel() { },
  //     });
  // }

  deActivateUser(userId) {
    let values = {
      userId: userId,
    };

    let users = this.state.userData;
    let index = users.findIndex((x) => x._id === userId);

    const status = users[index].isActive;

    const obj = {
      userId: users[index]._id,
      username: users[index].username,
      firstname: users[index].firstname,
      lastname: users[index].lastname,
      profilePhoto: users[index].profilePhoto,
      coverPhoto: users[index].coverPhoto,
      phone: users[index].phone,
      address: users[index].address,
      city: users[index].city,
      state: users[index].state,
      country: users[index].country,
      geo: users[index].geo,
      gender: users[index].gender,
      bio: users[index].bio,
      isActive: !status,
    };

    let userObj = {
      searchString: this.state.search_string,
      page: this.state.currentPage,
      sortValue: this.state.sortValue,
      sortOrder: this.state.sortOrder,
      pageSize: this.state.pageSize,
    };
    this.props.updateProfile_user(obj, userObj);
  }
  searchString(e) {
    this.setState({
      search_string: e.target.value,
    });
    let obj = {
      string: e.target.value,
      page: this.state.currentPage,
      sort_by: this.state.sortValue,
      sort_order: this.state.sortOrder,
      limit: this.state.pageSize,
    };
    this.props.getArmy(obj);
  }

  changePage(page) {
    this.setState({
      currentPage: page,
    });
    let obj = {
      string: this.state.search_string,
      page: page,
      sort_by: this.state.sortValue,
      sort_order: this.state.sortOrder,
      limit: this.state.pageSize,
    };
    this.props.getArmy(obj);
  }

  handleChange(value) {
    this.setState({
      pageSize: value,
      currentPage: 1,
    });

    let obj = {
      string: this.state.search_string,
      page: 1,
      sort_by: this.state.sortValue,
      sort_order: this.state.sortOrder,
      limit: value,
    };
    this.props.getArmy(obj);
  }

  exportRecord() {
    let html = document.querySelector("table").outerHTML;
    export_table_to_csv(html, "user.csv");
  }

  sortData(sortVal, name, email, created) {
    let obj = {
      searchString: this.state.search_string,
      page: this.state.currentPage,
      sortValue: sortVal,
      sortOrder: this.state.sortOrder === -1 ? 1 : -1,
      pageSize: this.state.pageSize,
    };
    this.setState({
      sortOrder: this.state.sortOrder === -1 ? 1 : -1,
      sortValue: sortVal,
      nameSort: name,
      emailSort: email,
      createdSort: created,
    });

    this.props.getArmy(obj);
  }
  showModal = (obj) => {
    this.setState({
      visible: true,
      userId: obj._id,
      editUser: obj,
    });

    this.props.form.setFieldsValue({
      firstname: obj.firstname,
      lastname: obj.lastname,
      address: obj.address,
    });
  };
  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      var edit_userData = this.state.editUser;
      if (!err) {
        const obj = {
          userId: edit_userData._id,
          username: edit_userData.username,
          firstname: values.firstname,
          lastname: values.lastname,
          profilePhoto: edit_userData.profilePhoto,
          coverPhoto: edit_userData.coverPhoto,
          phone: edit_userData.phone,
          address: values.address,
          city: edit_userData.city,
          state: edit_userData.state,
          country: edit_userData.country,
          geo: edit_userData.geo,
          gender: edit_userData.gender,
          bio: edit_userData.bio,
          isActive: edit_userData.isActive,
        };
        let userObj = {
          searchString: this.state.search_string,
          page: this.state.currentPage,
          sortValue: this.state.sortValue,
          sortOrder: this.state.sortOrder,
          pageSize: this.state.pageSize,
        };
        this.props.updateProfile_user(obj, userObj);

        this.setState({
          visible: false,
          userId: "",
          editUser: [],
        });
      }
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false,
      userId: "",
      editUser: [],
    });
  };

  componentWillReceiveProps(nextProps) {
    let data = nextProps.userData;
    this.setState({
      userData: data,
      totalRecord: nextProps.totalCount,
      loading: false,
    });
  }

  showConfirm(recordId) {
    let self = this;
    let obj = {
      recordId: recordId,
    };

    confirm({
      title: constant.DELETE_RECORD,
      content: "",
      onOk:()=> {
        self.props.removeRecord(obj, self.refreshRecords);
        this.setState({
          loading:true
       })
      },
      onCancel() {},
    });
  }

  refreshRecords = () => {
    let objUser = {
      string: this.state.search_string,
      page: this.state.currentPage,
      sort_by: this.state.sortValue,
      sort_order: this.state.sortOrder,
      limit: this.state.pageSize,
    };
    this.props.getArmy(objUser);
  };

  viewClick = async (x) => {
    await this.props.getRecord(x);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { Option } = Select;

    let state = this.state;
    let props = this.props;

    const users = state.userData.map((x, index) => (
      <tr key={index}>
        <td>
          {(this.state.currentPage - 1) * this.state.pageSize + index + 1}
        </td>

        <td>{x.baseName.charAt(0).toUpperCase() + x.baseName.slice(1)}</td>
        <td>{x.state.charAt(0).toUpperCase() + x.state.slice(1)}</td>
        <td>{x.code.toUpperCase()}</td>
        <td>
          <Link to={"/editRecord/army/" + x._id}>
            {" "}
            <Button
              style={{ height: "30px" }}
              className="btn btn-info btn-sm mr-2"
            >
              <i className="fa fa-pencil"></i>
            </Button>
          </Link>
          <Link to={"/viewRecord/" + x._id}>
            {" "}
            <Button
              style={{ height: "30px" }}
              className="btn btn-info btn-sm mr-2"
              title="Sub Category"
            >
              <i className="fa fa-eye"></i>
            </Button>
          </Link>
          <Button
            style={{ height: "30px" }}
            className="btn btn-info btn-sm mr-2"
            onClick={this.showConfirm.bind(this, x._id)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </td>
      </tr>
    ));

    return (
      <>
        <div className="box box-default mb-4" style={{ fontSize: "12px" }}>
          <PageHeader
            title="ARMY"
            className="pageHeader-18"
            subTitle=""
            extra={
              [
                // <Button key="export" type="dashed" size="small" onClick={this.exportRecord.bind(this)} title="export csv">
                //     <i className="fa fa-download"></i>
                // </Button>,
                //<Link to={"/addRecord/" + 'army'}> <Button className="btn btn-info btn-sm mr-2">Add</Button></Link>
                // <Input placeholder="Search" onChange={this.searchString.bind(this)} key="search" style={{ width: 'auto' }} />,
              ]
            }
          />
          <Spin spinning={this.state.loading} delay={500}>
            <div
              className="box-body"
              style={{ marginBottom: "-18px", marginTop: "-10px" }}
            >
              <Link to={"/addRecord/" + "army"}>
                {" "}
                <Button className="btn btn-info btn-sm mr-2">Add Record</Button>
              </Link>

              <Input
                placeholder="Search"
                onChange={this.searchString.bind(this)}
                key="search"
                style={{ width: "auto", float: "right" }}
              />
            </div>
            <div className="box-body">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "10%" }}>
                      Sr. No.
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      Base Name
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      State
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      Code
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users.length ? (
                    users
                  ) : (
                    <tr>
                      <td style={{ textAlign: "center" }} colSpan="5">
                        {state.noItems}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Row>
                <Col span={8}>
                  <Select
                    defaultValue="10"
                    style={{ width: "104px", "font-size": "12px" }}
                    onChange={this.handleChange.bind(this)}
                  >
                    <Option value="10">10 / Page</Option>
                    <Option value="20">20 / Page</Option>
                    <Option value="50">50 / Page</Option>
                    <Option value="100">100 / Page</Option>
                  </Select>
                </Col>

                <Col span={16}>
                  <Pagination
                    style={{ textAlign: "right", "font-size": "12px" }}
                    pageSize={this.state.pageSize}
                    defaultCurrent={this.state.currentPage}
                    onChange={this.changePage.bind(this)}
                    total={this.state.totalRecord ? this.state.totalRecord : 1}
                    showTotal={(total) => `Total ${total} items`}
                  />
                </Col>
              </Row>

              <CustomModal
                handleCancel={this.handleCancel}
                handleOk={this.handleOk}
                visible={this.state.visible}
                title="Army Lounge Details"
                backBtnText="Back"
                submitBtnText="Submit"
              >
                <Form className="user">
                  <Row>
                    <Col span={11}>
                      <Form.Item label="BaseName">
                        {getFieldDecorator("baseName", {
                          rules: [
                            { transform: (value) => value.trim() },
                            {
                              required: true,
                              message: "Please enter BaseName",
                            },
                          ],
                        })(<Input placeholder="Enter BaseName" />)}
                      </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>
                      <Form.Item label="BaseCode">
                        {getFieldDecorator("code", {
                          rules: [
                            { transform: (value) => value.trim() },
                            {
                              required: true,
                              message: "Please enter BaseName",
                            },
                          ],
                        })(<Input placeholder="Enter BaseName" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={11}>
                      <Form.Item label="State">
                        {getFieldDecorator("baseName", {
                          rules: [
                            { transform: (value) => value.trim() },
                            {
                              required: true,
                              message: "Please enter BaseName",
                            },
                          ],
                        })(<Input placeholder="Enter BaseName" />)}
                      </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>
                      <Form.Item label="Phone">
                        {getFieldDecorator("baseName", {
                          rules: [
                            { transform: (value) => value.trim() },
                            {
                              required: true,
                              message: "Please enter BaseName",
                            },
                          ],
                        })(<Input placeholder="Enter BaseName" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={11}>
                      <Form.Item label="Lat">
                        {getFieldDecorator("baseName", {
                          rules: [
                            { transform: (value) => value.trim() },
                            {
                              required: true,
                              message: "Please enter BaseName",
                            },
                          ],
                        })(<Input placeholder="Enter BaseName" />)}
                      </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>
                      <Form.Item label="Long">
                        {getFieldDecorator("baseName", {
                          rules: [
                            { transform: (value) => value.trim() },
                            {
                              required: true,
                              message: "Please enter BaseName",
                            },
                          ],
                        })(<Input placeholder="Enter BaseName" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={11}>
                      <Form.Item label="Address">
                        {getFieldDecorator("baseName", {
                          rules: [
                            { transform: (value) => value.trim() },
                            {
                              required: true,
                              message: "Please enter BaseName",
                            },
                          ],
                        })(<Input placeholder="Enter BaseName" />)}
                      </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>
                      <Form.Item label="BaseEmail">
                        {getFieldDecorator("baseName", {
                          rules: [
                            { transform: (value) => value.trim() },
                            {
                              required: true,
                              message: "Please enter BaseName",
                            },
                          ],
                        })(<Input placeholder="Enter BaseName" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </CustomModal>
            </div>
          </Spin>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  console.log(state);
  return {
    userData: state.user.armyData,
    totalCount: state.user.totalCountArmy,
  };
}

const User = Form.create({ name: "user" })(Users);

export default connect(mapStateToProps, {
  getArmy,
  removeRecord,
  deActivateUser,
  deleteUser,
  updateProfile_user,
  getRecord,
})(User);
