import React from "react";
import { Card, Rate, PageHeader, Spin, Button, Typography } from "antd";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import moment from "moment";
import UserProduct from "./userProduct";
import history from "../../history";
import autoBind from "react-autobind";
import { getRecord } from "./actions/armyActions";

import { formatter } from "../../actions/constant";
import * as constant from "../../actions/constant";

const { Meta } = Card;
const { Text } = Typography;

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      singleRecord: [],
      loading: false,
      page: 0,
    };
    autoBind(this);
  }

  componentDidMount() {
    let obj = {
      recordId: this.props.match.params.recordId,
    };
    console.log(obj);

    this.props.getRecord(obj);
  }

  componentWillReceiveProps(nextProps) {

    this.setState({
      singleRecord: nextProps.singleRecord
    });
  
    
  
  }

 

  render() {
    let state = this.state;

    console.log("statestatestatestatestate", state);

    let user = state.userDetails ? state.userDetails : "";

    let singleRecord = state.singleRecord;

    let image = constant.IMAGE_URL + singleRecord.image;

    const roomType = singleRecord.roomType;

    const amenities = singleRecord.amenities;

    const roomExtras = singleRecord.roomExtras;

    let direction = singleRecord.direction;

    let lat = direction ? direction.lat: null

    let long = direction ? direction.long: null

    return (
      <>
        <div className="overflowBox">
          <PageHeader
            onBack={() => history.goBack()}
            title="View"
            subTitle=""
          />
          <div className="box box-default mb-4">
            <div className="box-body">
              <div className="row profileDetails">
                <div className="col-sm-12 col-md-12">
                  <div className="row" style={{fontSize:'13px'}}>
                    <div className="col-sm-12 col-md-12" 
                    style={{textAlign:"center"}}
                    >
                      <img
                        src={image}
                        alt="profile_image"
                        className="img-rounded img-responsive ht_150"
                        height="225"
                        width="225"
                      />
                    </div>
                    <div className="col-sm-12 col-md-12">
                      

                      <p>
                      <b>Base Name:-</b> {singleRecord.baseName}
                        <br />
                        <b>Base Code:-</b> {singleRecord.code}
                        <br />
                        <b>State:-</b> {singleRecord.state}
                        <br />
                        <b>Lat:- </b>  {lat}
                        <br />
                        <b>Long:- </b>  {long}
                        <br />
                        <b> Phone:- </b> {singleRecord.phone}
                        <br />
                        <b> BaseEmail:-</b> {singleRecord.baseEmail}
                        <br />
                        <b>Website Link:-</b> {singleRecord.websiteLink}
                        <br />
                        <b>Address:- </b> {singleRecord.address}
                        <br />
                        <b>OverView:- </b> <span>{singleRecord.overView}</span>
                        <br />

                        <b> Room Type</b>
                    <ul>
                      {roomType && roomType.length > 0
                        ? roomType.map((item) => <li key={item}>{item}</li>)
                        : null}
                    </ul>
                    <br />
                    <b> Amenties</b>
                    <ul>
                      {amenities && amenities.length > 0
                        ? amenities.map((item) => <li key={item}>{item}</li>)
                        : null}
                    </ul>
                    <br />
                    <b> Room Extra</b>
                    <ul>
                      {roomExtras && roomExtras.length > 0
                        ? roomExtras.map((item) => <li key={item}>{item}</li>)
                        : null}
                    </ul>
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6"></div>
                </div>
                
              </div>
              
              <hr />
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    singleRecord: state.user.singleRecord,
  };
}
export default connect(mapStateToProps, { getRecord })(UserProfile);
