
import React, { Component } from 'react';

class Ticket extends Component {

    render() {

        return (<> <h3>Ticket Analysis</h3> </>
        );
    }
}


export default Ticket;
