
import React, { Component } from 'react';
import { Statistic, Card, Col, Row, DatePicker } from "antd";
import tradeDown from '../../../img/trending-down.png';
import tradeUp from '../../../img/trending-up.png';
import { connect } from 'react-redux';
import { getAdminDashboardCounts, getGraphByMonth } from '../actions/adminActions';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import moment from 'moment';
const { MonthPicker } = DatePicker;


class Dashboard extends Component {

  componentDidMount() {

    this.props.getAdminDashboardCounts();
    this.props.getGraphByMonth(new Date());
  }

  changeDate = (date) => {

    this.props.getGraphByMonth(date._d);
  }

  render() {

    let props = this.props;
    let counts = props.dashboardCounts ? props.dashboardCounts : '';

    let data = this.props.graphStats;


    if (data.length > 0) {
      var newArray = []
      for (var x = 0; x < data.length; x++) {
        newArray[x] = {

          Day: 'Day ' + data[x].day,
          User: data[x].User,
          Sale: data[x].Sale,
          Product: data[x].Product

        }

      }
      data = newArray;
    }

    return (<><Row>
      {/* <PageHeader
        title="Dashboard"
        subTitle=""
      /> */}
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <div className="gutter-box">
            <Card hoverable className="mb-4"
            //onClick={() => this.props.history.push('/users')}
            >
              <Statistic
                title="Army"
                value={counts.army}
                precision={0}
                valueStyle={{ color: '#F9897F' }}
                prefix={<img alt="trade" src={tradeUp} />}
              // suffix="%"
              />
            </Card>
          </div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div className="gutter-box">
            <Card hoverable className="mb-4"
            // onClick={() => this.props.history.push('/users')}
            >
              <Statistic
                title="Navy"
                value={counts.navy}
                precision={0}
                valueStyle={{ color: '#8884d8' }}
                prefix={<img alt="trade" src={tradeUp} />}
              // suffix="%"
              />
            </Card>
          </div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div className="gutter-box">
            <Card hoverable className="mb-4"
            // onClick={() => this.props.history.push('/users')}
            >
              <Statistic
                title="Marine"
                value={counts.marines}
                precision={0}
                valueStyle={{ color: '#82ca9d' }}
                prefix={<img alt="trade" src={tradeUp} />}
              // suffix="%"
              />
            </Card>
          </div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div className="gutter-box">
            <Card hoverable className="mb-4"
            // onClick={() => this.props.history.push('/users')}
            >
              <Statistic
                title="Air Force"
                value={counts.airforce}
                precision={0}
                valueStyle={{ color: '#82ca9d' }}
                prefix={<img alt="trade" src={tradeUp} />}
              // suffix="%"
              />
            </Card>
          </div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div className="gutter-box">
            <Card hoverable className="mb-4"
            // onClick={() => this.props.history.push('/users')}
            >
              <Statistic
                title="Space Force"
                value={counts.spaceforce}
                precision={0}
                valueStyle={{ color: '#82ca9d' }}
                prefix={<img alt="trade" src={tradeUp} />}
              // suffix="%"
              />
            </Card>
          </div>
        </Col>
      </Row>

    </Row>
    </>
    );
  }
}


function mapStateToProps(state) {
  return {
    dashboardCounts: state.admin.dashboardCounts,
    graphStats: state.admin.graphStats
  };
}
export default connect(mapStateToProps, { getAdminDashboardCounts, getGraphByMonth })(Dashboard);
