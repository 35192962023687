/** User Api Constants */
     
import {
    actionCreator,
    createRequestActionTypes,
    jsonApiHeader,
} from './../../../actions/utilAction';
export {
    actionCreator,
    jsonApiHeader
};

export const userActionTypes = {
    get_users: createRequestActionTypes('GET_USER'),
    get_userDetails: createRequestActionTypes('GET_USER_DETAILS'),
    get_userInfo: createRequestActionTypes('GET_USER_INFO'),
    get_armyInfo: createRequestActionTypes('GET_ARMY_INFO'),
    get_navyInfo: createRequestActionTypes('GET_NAVY_INFO'),
    get_marineInfo: createRequestActionTypes('GET_MARINE_INFO'),
    get_airforceInfo: createRequestActionTypes('GET_AIRFORCE_INFO'),
    get_spaceforceInfo: createRequestActionTypes('GET_SPACEFORCE_INFO'),
    get_singleRecord: createRequestActionTypes('GET_SINGLE_RECORD'),


};
