/** User Reducers  */

import { userActionTypes } from "./../constants/apiConstants";

const initialState = {
  userData: [],
  userDetails: "",
  totalCount: 0,
  armyData: [],
  totalCountArmy: 0,
  navyData: [],
  totalCountNavy: 0,
  marineData: [],
  totalCountMarine: 0,
  airforceData: [],
  totalCountAirforce: 0,
  singleRecord:[],
  spaceforceData:[],
  totalCountSpaceforce:0
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case userActionTypes.get_users.SUCCESS:
      return {
        ...state,
        userData: payload.userData,
        totalCount: payload.totalCount,
      };
    case userActionTypes.get_userDetails.SUCCESS:
      return {
        ...state,
        userDetails: payload.userDetails,
      };
    case userActionTypes.get_userInfo.SUCCESS:
      return {
        ...state,
        userInfo: payload.userInfo,
      };

    case userActionTypes.get_armyInfo.SUCCESS:
      return {
        ...state,
        armyData: payload.armyData,
        totalCountArmy: payload.totalCount,
      };
      case userActionTypes.get_navyInfo.SUCCESS:
      return {
        ...state,
        navyData: payload.navyData,
        totalCountNavy: payload.totalCount,
      };
      case userActionTypes.get_marineInfo.SUCCESS:
      return {
        ...state,
        marineData: payload.marineData,
        totalCountMarine: payload.totalCount,
      };
      case userActionTypes.get_airforceInfo.SUCCESS:
      return {
        ...state,
        airforceData: payload.airforceData,
        totalCountAirforce: payload.totalCount,
      };
      case userActionTypes.get_spaceforceInfo.SUCCESS:
      return {
        ...state,
        spaceforceData: payload.spaceforceData,
        totalCountSpaceforce: payload.totalCount,
      };

      case userActionTypes.get_singleRecord.SUCCESS:
        console.log('cocococo',payload)
      return {
        ...state,
        singleRecord: payload.singleRecord,
        
      };

    default:
      return state;
  }
};
