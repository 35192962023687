/** User Actions  */
import {
    actionCreator,
    userActionTypes,
    jsonApiHeader,
} from './../../user/constants/apiConstants';

import { loginActionTypes } from './../../login/constants/apiConstants';
import { API_URL } from '../../../actions/utilAction';
import axios from 'axios';
import * as constant from '../../../actions/constant';
import toastr from 'toastr';

let access_token = localStorage.access_token;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token
    },
}

export function getArmy(obj) {
    let access_token = localStorage.access_token;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }
    return (dispatch) => {

        obj.branch = 'army';

        axios.post(API_URL + constant.RECORD_LIST,obj ,config)
            .then(function (response) {

                if (response.data.status === true) {

                    dispatch(actionCreator(userActionTypes.get_armyInfo.SUCCESS, { armyData: response.data.data.data, totalCount: response.data.data.totalCount }));
                }else{
                    dispatch(actionCreator(userActionTypes.get_armyInfo.SUCCESS, { armyData: [], totalCount: 0 }));
                } 
            });
    };
}



export function getNavy(obj) {
    let access_token = localStorage.access_token;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }
    return (dispatch) => {

        obj.branch = 'navy';

        axios.post(API_URL + constant.RECORD_LIST,obj ,config)
            .then(function (response) {

                if (response.data.status === true) {

                    dispatch(actionCreator(userActionTypes.get_navyInfo.SUCCESS, { navyData: response.data.data.data, totalCount: response.data.data.totalCount }));
                }else{
                    dispatch(actionCreator(userActionTypes.get_navyInfo.SUCCESS, { navyData: [], totalCount: 0 }));
                } 
            });
    };
}



export function getMarine(obj) {
    let access_token = localStorage.access_token;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }
    return (dispatch) => {

        obj.branch = 'marines';

        axios.post(API_URL + constant.RECORD_LIST,obj ,config)
            .then(function (response) {

                if (response.data.status === true) {

                    dispatch(actionCreator(userActionTypes.get_marineInfo.SUCCESS, { marineData: response.data.data.data, totalCount: response.data.data.totalCount }));
                }else{
                    dispatch(actionCreator(userActionTypes.get_marineInfo.SUCCESS, { marineData: [], totalCount: 0 }));
                } 
            });
    };
}





export function getAirforce(obj) {
    let access_token = localStorage.access_token;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }
    return (dispatch) => {

        obj.branch = 'airforce';

        axios.post(API_URL + constant.RECORD_LIST,obj ,config)
            .then(function (response) {

                if (response.data.status === true) {

                    dispatch(actionCreator(userActionTypes.get_airforceInfo.SUCCESS, { airforceData: response.data.data.data, totalCount: response.data.data.totalCount }));
                }else{
                    dispatch(actionCreator(userActionTypes.get_airforceInfo.SUCCESS, { airforceData: [], totalCount: 0 }));
                } 
            });
    };
}

export function getSpaceforce(obj) {
    let access_token = localStorage.access_token;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }
    return (dispatch) => {

        obj.branch = 'spaceforce';

        axios.post(API_URL + constant.RECORD_LIST,obj ,config)
            .then(function (response) {

                if (response.data.status === true) {

                    dispatch(actionCreator(userActionTypes.get_spaceforceInfo.SUCCESS, { spaceforceData: response.data.data.data, totalCount: response.data.data.totalCount }));
                }else{
                    dispatch(actionCreator(userActionTypes.get_spaceforceInfo.SUCCESS, { spaceforceData: [], totalCount: 0 }));
                } 
            });
    };
}

export function deActivateUser(values) {
    let access_token = localStorage.access_token;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }

    return (dispatch) => {
        axios.post(API_URL + constant.DEACTIVATE_USER, values, {
            headers: jsonApiHeader(access_token, 'application/json')
        }
        ).then(function (response) {
            if (response.data.code === 200) {
                axios.post(API_URL + constant.USER_LIST, values, {
                    headers: jsonApiHeader(access_token, 'application/json')
                })
                    .then(function (response) {
                        if (response.data.code === 200) {
                            dispatch(actionCreator(userActionTypes.get_users.SUCCESS, { userData: response.data.data }));
                        }
                    });
                toastr.success(response.data.message)
            } else {
                toastr.error(response.data.message)
            }
        });
    };
}

export function getUserDetails(obj) {
    let access_token = localStorage.access_token;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }

    return (dispatch) => {

        axios.get(API_URL + constant.USER_DETAILS + '?id=' + obj.userId, config)
            .then(function (response) {
                if (response.data.status === true) {

                    dispatch(actionCreator(userActionTypes.get_userDetails.SUCCESS, { userDetails: response.data.data }));
                }
            });
    };
}

export function deleteUser(obj, userObj) {
    let access_token = localStorage.access_token;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }
    return (dispatch) => {
        axios.post(API_URL + constant.USER_DELETE, obj, config)
            .then(function (response) {

                if (response.data.status === true) {
                    toastr.success(response.data.message)
                    axios.get(API_URL + constant.USER_LIST + '?page=' + userObj.page + '&limit='+userObj.pageSize + '&searchString=' + userObj.searchString +'&sortValue=' + userObj.sortValue +'&sortOrder=' + userObj.sortOrder, config)
                        .then(function (response) {
                            if (response.data.status === true) {
                                dispatch(actionCreator(userActionTypes.get_users.SUCCESS, { userData: response.data.data, totalCount: response.data.totalCount }));
                            } else if (response.data.status === false) {
                                dispatch(actionCreator(userActionTypes.get_users.SUCCESS, { userData: response.data.data, totalCount: response.data.totalCount }));
                            }
                        });
                }
            });
    };
}

export function updateProfile(obj) {

    let access_token = localStorage.access_token;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }

    return async (dispatch) => {

        let file_name = '';
        if (!obj.image_edit) {
            
            let formdata = new FormData();
            formdata.append("file", obj.categoryIcon[0]);

            const imgResponse = await axios.post(API_URL + constant.UPLOADMEDIA, formdata, config)

            if (imgResponse.data.status) {
                file_name = imgResponse.data.data.file;
            }
        }

        const newObj= {
            "firstname": obj.firstname,
        "lastname": obj.lastname,
        "address": obj.address,
        }
        
        if (!obj.image_edit) {
            newObj.profilePhoto = file_name
        }
     
        
        axios.post(API_URL + constant.USER_UPDATE, newObj, config)
            .then(function (response) {

                if (response.data.status === true) {
                    toastr.success(response.data.message)
                    axios.post(API_URL + constant.PROFILE, null, config)
                        .then(function (response) {

                            if (response.data.status === true) {

                                
                                dispatch(actionCreator(userActionTypes.get_userInfo.SUCCESS, { userInfo: response.data.data }));

                            }
                        });


                }
            });
    };
}

export function updateProfilePic(formdata, obj) {

    let access_token = localStorage.access_token;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }


    return async (dispatch) => {

        var file_name = '';

        const imgResponse = await axios.post(API_URL + constant.UPLOADMEDIA, formdata, config)

        if (imgResponse.data.status) {
            file_name = imgResponse.data.data.file;

            

            const newobj = {
                "userId": obj._id,
                "username": obj.username,
                "firstname": obj.firstname,
                "lastname": obj.lastname,
                "profilePhoto": file_name,
                "coverPhoto": obj.coverPhoto,
                "phone": obj.phone,
                "address": obj.address,
                "city": obj.city,
                "state": obj.state,
                "country": obj.country,
                "geo": obj.geo,
                "gender": obj.gender,
                "bio": obj.bio,
                "isActive": obj.isActive
            }

            axios.post(API_URL + constant.USER_UPDATE, newobj, config)
                .then(function (response) {

                    if (response.data.status === true) {
                        toastr.success('Profile Picture Updated Successfully');
                        axios.post(API_URL + constant.PROFILE, null, config)
                            .then(function (response) {

                                if (response.data.status === true) {

                                   
                                    dispatch(actionCreator(userActionTypes.get_userInfo.SUCCESS, { userInfo: response.data.data }));

                                }
                            });


                    }
                });


        } else {
            toastr.warning('Image not uplaoded')
        }

    };
}

export function updateProfile_user(obj, userObj) {
    let access_token = localStorage.access_token;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }
    return (dispatch) => {

        axios.post(API_URL + constant.USER_UPDATE, obj, config)
            .then(function (response) {

                if (response.data.status === true) {
                    toastr.success('User Update Successfully')

                    axios.get(API_URL + constant.USER_LIST + '?page=' + userObj.page + '&limit='+userObj.pageSize + '&searchString=' + userObj.searchString +'&sortValue=' + userObj.sortValue +'&sortOrder=' + userObj.sortOrder, config)
                        .then(function (response) {
                            if (response.data.status === true) {
                                dispatch(actionCreator(userActionTypes.get_users.SUCCESS, { userData: response.data.data, totalCount: response.data.totalCount }));
                            } else if (response.data.status === false) {
                                dispatch(actionCreator(userActionTypes.get_users.SUCCESS, { userData: response.data.data, totalCount: response.data.totalCount }));
                            }
                        });
                }
            });
    };
}

export function getAdminDetails() {

    let access_token = localStorage.access_token;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }

    return (dispatch) => {


        axios.post(API_URL + constant.PROFILE, null, config)
            .then(function (response) {

                if (response.data.status === true) {
                    dispatch(actionCreator(userActionTypes.get_userInfo.SUCCESS, { userInfo: response.data.data }));
                }
            });
    };
}

export function removeRecord(obj, refreshRecords) {
    let access_token = localStorage.access_token;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }

    return (dispatch) => {
        axios.post(API_URL + constant.REMOVE_RECORD, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            data: obj
        })
            .then(function (response) {

                if (response.data.status === true) {
                    toastr.success(response.data.message)
                  
                    setTimeout(()=> {
                        refreshRecords()

                    },1500)
                }else{
                    toastr.warning(response.data.message)
                }
                
            });
    };
}




export function getRecord(obj) {
    let access_token = localStorage.access_token;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
    }



    
    return (dispatch) => {
        axios.post(API_URL + constant.SINGLE_RECORD, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            data: obj
        })
            .then(function (response) {
                if (response.data.status === true) {
                    dispatch(actionCreator(userActionTypes.get_singleRecord.SUCCESS, { singleRecord: response.data.data}));
                }else{
                    dispatch(actionCreator(userActionTypes.get_singleRecord.SUCCESS, { singleRecord: []}));
                } 
                
            });
    };
}