import React, { useEffect } from "react";
import {
  Card,
  Rate,
  PageHeader,
  Spin,
  Button,
  Typography,
  Form,
  Input,
  Row,
  Col,
  Icon,
  Checkbox
} from "antd";
import { Link } from "react-router-dom";
//import { getUserDetails } from './actions/userActions';
//import { getProductList } from '../product/actions/productActions';
import { connect } from "react-redux";
import moment from "moment";
import UserProduct from "./userProduct";
import history from "../../history";
import autoBind from "react-autobind";
import {
  PRODUCT_IMG_PATH,
  PROFILE_IMG_PATH,
  IMAGE_URL,
} from "../../actions/utilAction";
import { formatter } from "../../actions/constant";
import * as constant from "../../actions/constant";
import ImageUploader from "react-images-upload";

import { addRecord } from '../admin/actions/adminActions';


const FormItem = Form.Item;

const { Meta } = Card;
const { Text } = Typography;



class AddRecord extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        categoryData: [],
            pictures: [],
            image_name: '',
            //file: null,
            image_val_chk: false,
            roomExtra:["asd","adssda"]
    };


    autoBind(this);


  }



  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
        pictures: pictureFiles,
        //file: URL.createObjectURL(pictureFiles[0]),
        image_val_chk: true
    });
    console.log(pictureFiles);

}

handleCancel = () => {
    this.setState({
        pictures: [],
       // file: null,
        image_val_chk: false
    });

};


  handleSubmit = (e) => {
    e.preventDefault();

    if(this.state.pictures.length === 0){
        alert('Kindly select Image')

    }else{
    this.props.form.validateFields((err, values) => {

      if (!err) {

        let amenities = values.amenitiesString.split(',');
        let roomExtras = values.roomExtrasString.split(',');

        let roomType = values.roomTypeString.split(',');

        values.amenities = amenities;
        values.roomExtras = roomExtras;
        values.roomType = roomType;
        values.branch = 'army';
        
        let obj = {
          values: values,
          image: this.state.pictures,
          branch: 'army'
        }
        this.props.addRecord(obj,this.redirectArmy)
      }
    });
}
  };


  redirectArmy = () => {

    this.props.history.push("/army");

};
  
  render() {
    const { getFieldDecorator } = this.props.form;
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };
    return (
      <>
        <div className="overflowBox">
          <PageHeader
            onBack={() => history.goBack()}
            title="Back"
            subTitle=""
            style={{marginTop:'-23px', marginBottom:'-12px'}}
          />
          <Row>
            <div className="gx-ads-container">
              <div className="gx-ads-content">
                <div
                  className="gx-mb-4"
                  style={{ textAlign: "center", marginBottom: "25px" }}
                >
                  <h6>Add Army Record</h6>
                </div>

                <Form
                  {...layout}
                  onSubmit={this.handleSubmit}
                  className="gx-login-form gx-form-row0"
                >
                     <Row style={{marginTop:'-22px',marginBottom:'-5px'}}>
                    <ImageUploader
                      withIcon={false}
                      withPreview={true}
                      buttonText="Choose Image"
                      onChange={this.onDrop.bind(this)}
                      imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                      maxFileSize={5242880}
                      singleImage={true}
                      label="Max file size: 5MB, Format Accepted: JPG|GIF|PNG"
                    />
                  </Row>

                  <Row>
                    <Col span={11}>

                      <Form.Item label="BaseName">
                        {getFieldDecorator("baseName", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter BaseName",
                            },
                          ],
                        })(<Input placeholder="Enter BaseName" />)}
                      </Form.Item>



                    </Col>
                    <Col span={1}></Col>
                    <Col span={11}>
                      <Form.Item label="BaseCode">
                        {getFieldDecorator("code", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter BaseCode",
                            },
                          ],
                        })(<Input placeholder="Enter BaseCode" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={11}>
                      <Form.Item label="State">
                        {getFieldDecorator("state", {
                          rules: [
                            { required: true, message: "Please enter State" },
                          ],
                        })(<Input placeholder="Enter State" />)}
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={11}>
                      <Form.Item label="Phone">
                        {getFieldDecorator("phone", {
                          rules: [
                            { required: true, message: "Please enter Phone" },
                          ],
                        })(<Input placeholder="Enter Phone" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={11}>
                      <Form.Item label="Lat">
                        {getFieldDecorator("lat", {
                          rules: [
                            { required: true, message: "Please enter Lat" },
                          ],
                        })(<Input placeholder="Enter Lat" />)}
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={11}>
                      <Form.Item label="Long">
                        {getFieldDecorator("long", {
                          rules: [
                            { required: true, message: "Please enter Long" },
                          ],
                        })(<Input placeholder="Enter Long" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={11}>
                      <Form.Item label="Address">
                        {getFieldDecorator("address", {
                          rules: [
                            { required: true, message: "Please enter Address" },
                          ],
                        })(<Input placeholder="Enter Address" />)}
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={11}>
                      <Form.Item label="BaseEmail">
                        {getFieldDecorator("baseEmail", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter BaseEmail",
                            },
                          ],
                        })(<Input placeholder="Enter BaseEmail" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={11}>
                      <Form.Item label="Website Link">
                        {getFieldDecorator("websiteLink", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter Website Link",
                            },
                          ],
                        })(<Input placeholder="Enter Website Link" />)}
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={11}>
                      <Form.Item label="Room Type"   style={{marginBottom: '0px'}}>
                        {getFieldDecorator("roomTypeString", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter Room Type",
                            },
                          ],
                        })(<Input placeholder="Enter Room Type" />)}
                      </Form.Item>
                      <div style={{fontSize:'11px',textAlign:"center" ,color: 'red', marginLeft:'140px'}}>
                      To Add Multiple values Enter Value like (AC,Regrigertor)
                      </div>
                    </Col>
                  </Row>

                  <Row style={{marginTop:'20px'}}>

                    <Col span={11}>
                      <Form.Item label="Room Extras"  style={{marginBottom: '0px'}} >
                        {getFieldDecorator("roomExtrasString", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter Room Extras",
                            },
                          ],
                        })(<Input placeholder="Enter Room Extras" />)}
                      </Form.Item>
                      <div style={{fontSize:'11px',textAlign:"center" ,color: 'red', marginLeft:'140px'}}>
                      To Add Multiple values Enter Value like (AC,Regrigertor)
                      </div>
                      
                    </Col>
                    <Col span={1}></Col>
                    <Col span={11}>
                      <Form.Item label="Amenties" style={{marginBottom: '0px'}} >
                        {getFieldDecorator("amenitiesString", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter Amenties",
                            },
                          ],
                        })(<Input placeholder="Enter Amenties" />)}
                      </Form.Item>

                      <div style={{fontSize:'11px',textAlign:"center" ,color: 'red', marginLeft:'140px'}}>
                      To Add Multiple values Enter Value like (AC,Regrigertor)
                      </div>
                    </Col>
                  </Row>
                  
                  <Row style={{marginTop:'20px'}}>
                   
                    <Col span={20}>
                      <Form.Item label="OverView">
                        {getFieldDecorator("overView", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter OverView",
                            },
                          ],
                        })(<Input placeholder="Enter OverView" />)}
                      </Form.Item>
                    </Col>
                    <Col span={4}></Col>
                  </Row>

                 

                  <FormItem>
                    <Row>
                    <Col span={2}>

                      </Col>
                      <Col span={5}>
                      <Button
            type="primary"
            htmlType="submit"
            className="btn btn-info btn-sm mr-2"
          >
            Save
          </Button>
                      </Col>
                     
                    </Row>
                  </FormItem>
                </Form>
              </div>
            </div>
          </Row>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    userDetails: state.user.userDetails,
    productList: state.product.productList,
  };
}

const WrappedAdForm = Form.create({ name: "addRecord" })(AddRecord);
export default connect(mapStateToProps,{addRecord})(WrappedAdForm);


