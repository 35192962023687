import React from 'react';
import { Layout, Menu, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import logo from '../img/logo.png';

const { Sider } = Layout;
const { SubMenu } = Menu;

class UserMenu extends React.Component {
  render() {
    let path = this.props.location.pathname;
    return (<Sider trigger={null} collapsible collapsed={this.props.collapsed}>
      <div style={{background:'white'}} >
      <img style={{marginBottom:'10px',marginTop:'9px'}} alt="logo" src={logo}></img>
      </div>
      {/* <div className="logo">
        <img alt="logo" src={logo}></img>
        </div> */}
      <Menu theme="dark" mode="inline" defaultSelectedKeys={[path]}>
        <Menu.Item key="/dashboard" onClick={() => this.props.history.push('/dashboard')} style={{marginTop:'1px'}}>
          <Icon type="dashboard" style={{color:'white'}} />
          <span className="unselectable">Dashboard</span>
        </Menu.Item>
        <Menu.Item key="/users" onClick={() => this.props.history.push('/users')}>
          <Icon type="user"  style={{color:'white'}} />
          <span className="unselectable">Users</span>
        </Menu.Item>
        <Menu.Item key="/army" onClick={() => this.props.history.push('/army')}>
          <Icon type="codepen"  style={{color:'white'}} />
          <span className="unselectable">Army</span>
        </Menu.Item>
        <Menu.Item key="/navy" onClick={() => this.props.history.push('/navy')}>
          <Icon type="codepen"  style={{color:'white'}} />
          <span className="unselectable">Navy</span>
        </Menu.Item>
        <Menu.Item key="/marines" onClick={() => this.props.history.push('/marines')}>
          <Icon type="codepen"  style={{color:'white'}} />
          <span className="unselectable">Marine</span>
        </Menu.Item>
        <Menu.Item key="/airforce" onClick={() => this.props.history.push('/airforce')}>
          <Icon type="codepen"  style={{color:'white'}} />
          <span className="unselectable">Air Force</span>
        </Menu.Item>
        <Menu.Item key="/spaceforce" onClick={() => this.props.history.push('/spaceforce')}>
          <Icon type="codepen"  style={{color:'white'}} />
          <span className="unselectable">Space Force</span>
        </Menu.Item>
{/*         
        <Menu.Item key="/subcategory" onClick={() => this.props.history.push('/subcategory')}>
          <Icon type="codepen" />
          <span>Sub-Category</span>
        </Menu.Item> */}
        {/* <Menu.Item key="/ads" onClick={() => this.props.history.push('/ads')}>
          <Icon type="notification" />
          <span>Manage Ads</span>
        </Menu.Item>
        <Menu.Item key="/ticket" onClick={() => this.props.history.push('/ticket')}>
          <Icon type="audit" />
          <span>Manage Ticket</span>
        </Menu.Item> */}
        {/* <SubMenu
          key="/category"
          title={
            <span>
              <Icon type="appstore" />
              <span>Master</span>
            </span>
          }
        >
          <Menu.Item key="5" onClick={() => this.props.history.push('/category')}>Manage Category</Menu.Item>
        </SubMenu> */}
      </Menu>
    </Sider>);
  }
}

export default withRouter(UserMenu);
