import React, { useEffect } from "react";
import {
  Card,
  Rate,
  PageHeader,
  Spin,
  Button,
  Typography,
  Form,
  Input,
  Row,
  Col,
  Icon,
  Checkbox
} from "antd";
import { Link } from "react-router-dom";
//import { getUserDetails } from './actions/userActions';
//import { getProductList } from '../product/actions/productActions';
import { connect } from "react-redux";
import moment from "moment";
import UserProduct from "./userProduct";
import history from "../../history";
import autoBind from "react-autobind";
import {
  PRODUCT_IMG_PATH,
  PROFILE_IMG_PATH,
  IMAGE_URL,
} from "../../actions/utilAction";
import { formatter } from "../../actions/constant";
import * as constant from "../../actions/constant";
import ImageUploader from "react-images-upload";

import { editRecord } from '../admin/actions/adminActions';
import { getRecord } from "./actions/armyActions";

const FormItem = Form.Item;

const { Meta } = Card;
const { Text } = Typography;



class AddRecord extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      singleRecord: [],
      pictures: ["1"],
      image_name: '',
      imageChk: true,
      file: null,
      recordId: '',
      loading: false
    };
    this.onDrop = this.onDrop.bind(this);

    autoBind(this);


  }

  componentDidMount() {
    let obj = {
      recordId: this.props.match.params.recordId,
    };

    this.props.getRecord(obj);
  }



  onDrop(pictureFiles, pictureDataURLs) {
    if (pictureFiles.length > 0) {
      if (pictureFiles[0].size <= 5242880) { // 5MB
        this.setState({
          pictures: pictureFiles,
          file: URL.createObjectURL(pictureFiles[0]),
          imageChk: false,
        });
      } else {
        console.error("File size exceeds the limit of 5MB");
      }
    }
  }
 validateWebsiteLink = (rule, value, callback) => {
  // Updated regex to allow URLs with or without http/https scheme
  const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(\/[^\s]*)?$/i;

  if (value && !urlRegex.test(value)) {
    callback('Please enter a valid Website Link');
  } else {
    callback();
  }
};
  handleCancel = () => {
    this.setState({
      pictures: [],
      imageChk: false
    });

  };


  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.pictures.length === 0) {
      alert('Kindly select Image')

    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.setState({
            loading: true,
          })
          Object.keys(values).forEach(key => {
            if (typeof values[key] === 'string') {
              values[key] = values[key].trim();
            }
          });
          let amenities = values?.amenitiesString.split(',');
          let roomExtras = values?.roomExtrasString.split(',');

          let roomType = values?.roomTypeString.split(',');

          values.amenities = amenities;
          values.roomExtras = roomExtras;
          values.roomType = roomType;
          values.branch = this.props?.match?.params?.record;
          let directions = {
            lat: values?.lat,
            long: values.long
          }

          values.direction = {
            lat: values?.lat,
            long: values?.long
          }


          let obj = {
            values: values,
            image: this.state?.pictures,
            branch: this.props?.match?.params?.record,
            imageChk: this.state?.imageChk,
            recordId: this.state?.recordId
          }
          this.props.editRecord(obj, this.redirectArmy)
        }
      });
    }
  };


  redirectArmy = () => {
    this.props.history.goBack();
    this.setState({
      loading: false,
    })
  };


  componentWillReceiveProps(nextProps) {

    this.setState({
      singleRecord: nextProps.singleRecord,
      recordId: nextProps.singleRecord._id
    });



  }

  render() {

    let state = this.state;

    let singleRecord = state?.singleRecord;


    let direction = singleRecord?.direction;

    let lat = direction ? direction?.lat : null

    let long = direction ? direction?.long : null

    let roomType = singleRecord?.roomType;

    let image = constant?.IMAGE_URL + singleRecord?.image;



    const { getFieldDecorator } = this.props.form;
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };
    return (
      <>
        <div className="overflowBox">
          <PageHeader
            onBack={() => history.goBack()}
            title="Back"
            subTitle=""
          />
          <Row>
            <div className="gx-ads-container">
              <div className="gx-ads-content">
                <div
                  className="gx-mb-4"
                  style={{ textAlign: "center", marginBottom: "25px" }}
                >
                  <h6>Edit Record</h6>
                </div>

                <Form
                  {...layout}
                  onSubmit={this.handleSubmit}
                  className="gx-login-form gx-form-row0"
                >


                  <Row>
                    <div style={{ textAlign: "center" }}>

                      {
                        this.state.imageChk ? (

                          <img
                            src={image}
                            alt="profile_image"
                            className="img-rounded img-responsive ht_150"
                            height="225"
                            width="225"
                          />
                        ) : (
                          <img
                            src={this.state.file}
                            alt="profile_image"
                            className="img-rounded img-responsive ht_150"
                            height="225"
                            width="225"
                          />

                        )
                      }



                    </div>
                  </Row>
                  <Row>
                    <ImageUploader
                      withIcon={false}
                      withPreview={false}
                      buttonText="Update Image"
                      onChange={this.onDrop.bind(this)}
                      imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                      maxFileSize={5242880}
                      singleImage={true}
                      label="Max file size: 5MB, Format Accepted: JPG|GIF|PNG"
                      defaultImages={[image]}
                    />
                  </Row>
                  <Row>
                    <Col span={11}>

                      <Form.Item label="BaseName">
                        {getFieldDecorator("baseName", {
                          initialValue: singleRecord?.baseName || '',
                          validateFirst: true,
                          rules: [{ required: true }, { validator: (_, value) => { if (!value || !value.trim()) { return Promise.reject(new Error('Please Enter BaseName!')) } return Promise.resolve() } }]
                        })(<Input placeholder="Enter BaseName" />)}
                      </Form.Item>



                    </Col>
                    <Col span={1}></Col>
                    <Col span={11}>
                      <Form.Item label="BaseCode">
                        {getFieldDecorator("code", {
                          initialValue: singleRecord?.code || '',

                          rules: [
                            {
                              required: false,
                              message: "Please enter BaseCode",
                            },
                          ],
                        })(<Input placeholder="Enter BaseCode" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={11}>
                      <Form.Item label="State">
                        {getFieldDecorator("state", {
                          initialValue: singleRecord?.state || '',
                          validateFirst: true,
                          rules: [{ required: true }, { validator: (_, value) => { if (!value || !value.trim()) { return Promise.reject(new Error('Please Enter State!')) } return Promise.resolve() } }]
                        })(<Input placeholder="Enter State" />)}
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={11}>
                      <Form.Item label="Phone">
                        {getFieldDecorator("phone", {
                          initialValue: singleRecord?.phone || '',
                          rules: [
                            { required: false, message: "Please enter Phone" },
                          ],
                        })(<Input placeholder="Enter Phone" onKeyPress={(event) => {
                          const keyCode = event.keyCode || event.which;
                          const keyValue = String.fromCharCode(keyCode);
                          // Allow only numeric characters and control keys
                          const regex = /^[0-9\b]+$/;
                          if (!regex.test(keyValue) || event.target.value.length >= 10) {
                            event.preventDefault();
                          }
                        }} />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={11}>
                      <Form.Item label="Lat">
                        {getFieldDecorator("lat", {
                          initialValue: lat || '',
                          rules: [
                            { required: false, message: "Please enter Lat" },
                          ],
                        })(<Input placeholder="Enter Lat" />)}
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={11}>
                      <Form.Item label="Long">
                        {getFieldDecorator("long", {
                          initialValue: long || '',
                          rules: [
                            { required: false, message: "Please enter Long" },
                          ],
                        })(<Input placeholder="Enter Long" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={11}>
                      <Form.Item label="Address">
                        {getFieldDecorator("address", {
                          initialValue: singleRecord?.address || '',
                          rules: [
                            { required: false, message: "Please enter Address" },
                          ],
                        })(<Input placeholder="Enter Address" />)}
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={11}>
                      <Form.Item label="BaseEmail">
                        {getFieldDecorator("baseEmail", {
                          initialValue: singleRecord?.baseEmail || '',
                          rules: [
                            {
                              validator: (_, value) => {
                                // Trim the value to remove any leading or trailing whitespace
                                const trimmedEmail = value ? value.trim() : '';
                    
                                // Regular expression to validate email format
                                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    
                                // Check if the trimmed value is empty
                                if (trimmedEmail && !emailRegex.test(trimmedEmail)) {
                                  // If it is not empty and does not match the email format, reject with an error
                                  return Promise.reject(new Error('Please enter a valid email'));
                                }
                    
                                // If the value is empty or matches the email format, resolve the promise
                                return Promise.resolve();
                              }
                            }
                          ]
                        })(<Input placeholder="Enter BaseEmail" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={11}>
                      <Form.Item label="Website Link">
                        {getFieldDecorator("websiteLink", {
                          initialValue: singleRecord?.websiteLink || '',
                          rules: [
                            {
                              required: false,
                              message: "Please enter Website Link",
                            },
                            {
                              validator: this.validateWebsiteLink,
                            },
                          ],
                        })(<Input placeholder="Enter Website Link" />)}
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={11}>
                      <Form.Item label="Room Type" style={{ marginBottom: '0px' }}>
                        {getFieldDecorator("roomTypeString", {

                          initialValue: singleRecord?.roomType + "" || '',

                          rules: [
                            {
                              required: false,
                              message: "Please enter Room Type",
                            },
                          ],
                        })(<Input placeholder="Enter Room Type" />)}
                      </Form.Item>
                      <div style={{ fontSize: '10px', textAlign: "center", color: 'red' }}>
                        To Add Multiple values Enter Value like (AC, Regrigertor)
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: '20px' }}>

                    <Col span={11}>
                      <Form.Item label="Room Extras" style={{ marginBottom: '0px' }} >
                        {getFieldDecorator("roomExtrasString", {
                          initialValue: singleRecord?.roomExtras + "" || '',
                          rules: [
                            {
                              required: false,
                              message: "Please enter Room Extras",
                            },
                          ],
                        })(<Input placeholder="Enter Room Extras" />)}
                      </Form.Item>
                      <div style={{ fontSize: '10px', textAlign: "center", color: 'red' }}>
                        To Add Multiple values Enter Value like (AC, Regrigertor)
                      </div>

                    </Col>
                    <Col span={1}></Col>
                    <Col span={11}>
                      <Form.Item label="Amenties" style={{ marginBottom: '0px' }} >
                        {getFieldDecorator("amenitiesString", {
                          initialValue: singleRecord?.amenities + "" || '',
                          rules: [
                            {
                              required: false,
                              message: "Please enter Amenties",
                            },
                          ],
                        })(<Input placeholder="Enter Amenties" />)}
                      </Form.Item>

                      <div style={{ fontSize: '10px', textAlign: "center", color: 'red' }}>
                        To Add Multiple values Enter Value like (AC, Regrigertor)
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: '20px' }}>

                    <Col span={20}>
                      <Form.Item label="OverView">
                        {getFieldDecorator("overView", {
                          initialValue: singleRecord?.overView || '',
                          rules: [
                            {
                              required: false,
                              message: "Please enter OverView",
                            },
                          ],
                        })(<Input placeholder="Enter OverView" />)}
                      </Form.Item>
                    </Col>
                    <Col span={4}></Col>
                  </Row>



                  <FormItem>
                    <Row>
                      <Col span={2}>

                      </Col>
                      <Col span={5}>
                        <Button
                          loading={this?.state?.loading}
                          type="primary"
                          htmlType="submit"
                          className="btn btn-info btn-sm mr-2"
                        >
                          Update
                        </Button>
                      </Col>

                    </Row>
                  </FormItem>
                </Form>
              </div>
            </div>
          </Row>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {

  return {
    singleRecord: state.user.singleRecord,
  };
}

const WrappedAdForm = Form.create({ name: "editRecord" })(AddRecord);
export default connect(mapStateToProps, { editRecord, getRecord })(WrappedAdForm);


